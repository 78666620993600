<template>
	<banner-style1 :position="2"></banner-style1>
	<div class="nav-details ul-none">
		<nav class="c w">
			<ul>
				<li v-for="item in list.list" :key="item.id">
					<span :class="{ on: item.id === listCurrent }" @click="navClick(item.id)">{{ item.title
          }}</span>
				</li>
			</ul>
		</nav>
		<div class="con c w">
			<div class="con-warp">
				<div class="content-html" v-html="contentHtml"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		watch,
		computed
	} from "vue";
	import {
		useRoute
	} from "vue-router";
	import {
		getCompetitionIntroduce
	} from '@/api/competition'

	const route = useRoute();
	const listCurrent = ref(1);
	if (route.query.point) {
		listCurrent.value = Number(route.query.point);
	}
	const list = reactive({
		list: []
	});
	getCompetitionIntroduce().then(res => {
		console.log(res, 'res')
		list.list = res.data
	})

	function navClick(i) {
		listCurrent.value = i;
	}
	let contentHtml = computed(() => {
		if (list.list.length) {
			let item = list.list.find(v => {
				return v.id == listCurrent.value
			})
			if (item) {
				return item.content
			} else {
				return list.list[0].content
			}
		}
	})
	watch(
		() => route.query.point,
		(newVla, oldVal) => {
			listCurrent.value = Number(newVla);
		}, {
			immediate: true,
			deep: true
		}
	);
</script>

<style lang="scss" scoped>
	.nav-details {
		padding-top: 40px;

		nav {
			font-weight: 700;
			background-color: #e5e5e5;
			padding: 20px 40px;
			box-sizing: border-box;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 1;
					text-align: center;

					span {
						display: inline-block;
						padding: 6px 20px;
						cursor: pointer;
					}
				}

				.on {
					background-color: #59ad96;
					color: #fff;
					border-radius: 4px;
				}
			}
		}
	}

	.con {
		.con-warp {
			border-top: 1px solid #e5e5e5;
			margin: 40px 0;
		}

		.content-html {
			padding: 20px 0;
		}
	}
</style>
